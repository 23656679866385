import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Form, Input, Select, Row, Col,Table, Button, Modal  } from 'antd';
import { MenuOutlined, SearchOutlined, EditOutlined  } from '@ant-design/icons';
import Sidebar from '../components/Sidebar';

import '../styles/Admin.css';
import '../styles/Edit.css';

import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';

const { Header, Content, Sider } = Layout;
const { Option } = Select;

pdfjs.GlobalWorkerOptions.workerSrc = `pdfjs-dist/build/pdf.worker.js`;

const Edit: React.FC = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const pdfFilePath = '/files/arquivo.pdf'; // Caminho do PDF

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const navigateToAdmin = function(){
    navigate('/admin');
  }

  const dataSource = [
    {
      key: '1',
      question: 'Atende LGPD?',
      answer: 'Sim',
    },
    {
      key: '2',
      question: 'Contrato de confidencialidade?',
      answer: 'Não',
    },
    {
      key: '3',
      question: 'Possui multa?',
      answer: 'Não',
    },
  ];

  const columns = [
    {
      title: 'Pergunta',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Resposta',
      dataIndex: 'answer',
      key: 'answer',
    },
    {
      title: 'Ação',
      key: 'action',
      render: () => (
        <Button icon={<EditOutlined />} className="btnAppPrimary" type="primary" onClick={handleOpenModal}>
          Avaliação
        </Button>
      ),
    },
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="header">
        <MenuOutlined className={`menu-icon ${sidebarVisible ? 'open' : ''}`} onClick={toggleSidebar} />
        <div className="header-title"></div>
        <div className="header-right">
          <Input className="search-bar" placeholder="Search..." prefix={<SearchOutlined />} />
          <div className="user-info">
            <span>Nira | Artificial Intelligence</span>
          </div>
        </div>
      </Header>
      <Layout>
        <Sider width={200} className={`site-layout-background ${sidebarVisible ? 'visible' : ''}`}>
          <Sidebar />
        </Sider>
        <Content id="contentSection" style={{ margin: '24px 16px 0', padding: 0 }}>

          <Row className="rowTitleRecord">
            <Col sm={24}>
                <h1>Análise de documentos</h1>
                <h4>Contrato_prestação_servicos.pdf</h4>
            </Col>
          </Row>

          <Row className="rowActionButtons">
            <Col sm={24}>
                <Button 
                    onClick={navigateToAdmin}
                    icon={<EditOutlined />} className="" type="default" >
                  Cancelar
                </Button>

                <Button 
                    onClick={navigateToAdmin}
                    icon={<EditOutlined />} className="btnAppPrimary" type="primary" >
                  Salvar
                </Button>
            </Col>
          </Row>

          <Row className="form-context" gutter={16} style={{ height: '100%' }}>
              <Col span={12} xs={24} sm={8} style={{ padding: '16px' }}>
                <div className="form-container">
                  <div className="form-column">
                    <Form layout="vertical">

                      <Form.Item label="Tipo de documento">
                        <Select value="br">
                          <Option value="br">Contrato de locação</Option>
                          <Option value="us">Acordo de confidencialidade</Option>
                          <Option value="uk">Prestação de serviços</Option>
                        </Select>
                      </Form.Item>

                      <Form.Item label="Nome do contrato">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Contratante">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Contratada">
                        <Input />
                      </Form.Item>

                      
                      <Form.Item label="Valor do contrato">
                        <Input />
                      </Form.Item>
                      <Form.Item label="Testemunhas">
                        <Input />
                      </Form.Item>

                      <Form.Item label="Resumo">
                        <Input.TextArea rows={10} placeholder="Resumo do seu contrato" />
                      </Form.Item>
                      
                    </Form>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={16}  style={{ padding: '16px' }}>
                  <div className="pdf-column">             
                    <object id="pdf-reader" data="https://jucisrs.rs.gov.br/upload/arquivos/201710/30150625-criacao-de-pdf-a.pdf" type="application/pdf" width="100%" height="100%">
                        <p>Alternative text - include a link <a href="https://jucisrs.rs.gov.br/upload/arquivos/201710/30150625-criacao-de-pdf-a.pdf">to the PDF!</a></p>
                    </object>
                  </div>
              </Col>

              <Col sm={24}>
                <div style={{ backgroundColor: 'rgb(82 58 191 / 57%)', padding: '20px', marginTop: '24px' }}>
                    <h3 style={{ margin: 0 }}>Validação do Contrato</h3>
                    <Table
                      dataSource={dataSource}
                      columns={columns}
                      pagination={false}
                      style={{ marginTop: '16px' }}
                    />
                  </div>
              </Col>
          </Row>

          <Modal
            title="Avaliação do Contrato"
            visible={isModalVisible}
            onCancel={handleCloseModal}
            footer={null}
          >
            <Row gutter={16}>
              <Col span={12}>
                <h4>Cláusula do contrato</h4>
                <p>Texto da cláusula do contrato aqui...</p>
              </Col>
              <Col span={12}>
                <h4>Sugestão da IA</h4>
                <p>Sugestão gerada pela IA aqui...</p>
              </Col>
            </Row>
          </Modal>

        </Content>
      </Layout>
    </Layout>
  );
};

export default Edit;