import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined, TagOutlined, UpOutlined, DownOutlined, DeleteOutlined, MenuOutlined, SearchOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Button, Input, Select, Form, Modal, Avatar, Tag } from 'antd';
import Sidebar from '../components/Sidebar';
import '../styles/FormCreator.css';

const { Header, Content, Sider } = Layout;
const { Option } = Select;
const { TextArea } = Input; // Define TextArea a partir de Input


interface Field {
  type: string;
  label: string;
  value: string;
  options?: string[]; // Adiciona a propriedade opcional 'options'
}

interface Column {
  fields: Field[];
}

interface Section {
  columns: Column[];
}

const FormCreator: React.FC = () => {

  const [sections, setSections] = useState<Section[]>([])
  
  /*
    const [sections, setSections] = useState<Section[]>([
        {
          columns: [
            {
              fields: [
                { type: 'input', label: 'Campo 1', value: '' },
                { type: 'input', label: 'Campo 2', value: '' },
                { type: 'input', label: 'Campo 3', value: '' },
              ],
            },
            {
              fields: [
                { type: 'input', label: 'Campo 1', value: '' },
                { type: 'input', label: 'Campo 2', value: '' },
                { type: 'input', label: 'Campo 3', value: '' },
              ],
            },
          ],
        },
        {
          columns: [
            {
              fields: [
                { type: 'input', label: 'Campo 1', value: '' },
                { type: 'input', label: 'Campo 2', value: '' },
                { type: 'input', label: 'Campo 3', value: '' },
              ],
            },
          ],
        },
      ]);
  */
 

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [newFieldLabel, setNewFieldLabel] = useState('');
  const [newFieldType, setNewFieldType] = useState('input');
  const [selectOptions, setSelectOptions] = useState('');
  const [selectedSectionIndex, setSelectedSectionIndex] = useState<number | null>(
    null
  );
  const [selectedColumnIndex, setSelectedColumnIndex] = useState<number | null>(
    null
  );

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isDevelopment, setIsDevelopment] = useState(true);
  
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const showModal = (sectionIndex: number, columnIndex: number) => {
    setSelectedSectionIndex(sectionIndex);
    setSelectedColumnIndex(columnIndex);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (selectedSectionIndex !== null && selectedColumnIndex !== null) {
      handleAddField(selectedSectionIndex, selectedColumnIndex);
    }
    setIsModalVisible(false);
    setNewFieldLabel('');
    setNewFieldType('input');
    setSelectOptions('');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNewFieldLabel('');
    setNewFieldType('input');
    setSelectOptions('');
  };

  const handleAddField = (sectionIndex: number, columnIndex: number) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      const newField: Field = { // Define o tipo do novo campo
        type: newFieldType,
        label: newFieldLabel,
        value: '',
      };

      if (newFieldType === 'select') {
        newField.options = selectOptions.split(',').map((option) => option.trim());
      }

      updatedSections[sectionIndex].columns[columnIndex].fields.push(newField);
      return updatedSections;
    });
  };

  const handleAddSection = (numColumns: number) => {
    const newSection: Section = {
      columns: Array.from({ length: numColumns }, () => ({ fields: [] })),
    };
    setSections([...sections, newSection]);
  };


  const handleRemoveField = (
    sectionIndex: number,
    columnIndex: number,
    fieldIndex: number
  ) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].columns[columnIndex].fields.splice(
        fieldIndex,
        1
      );
      return updatedSections;
    });
  };

  const handleFieldChange = (
    sectionIndex: number,
    columnIndex: number,
    fieldIndex: number,
    value: string
  ) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].columns[columnIndex].fields[fieldIndex].value =
        value;
      return updatedSections;
    });
  };

  const handleExport = () => {
    setIsExportModalVisible(true);
    setIsDevelopment(false);
  };

  const handleCloseExportModal = () => {
    setIsExportModalVisible(false);
  };

  const handleMoveFieldUp = (sectionIndex: number, columnIndex: number, fieldIndex: number) => {
    if (fieldIndex > 0) {
      setSections(prevSections => {
        return prevSections.map((section, sIndex) => {
          if (sIndex !== sectionIndex) return section;
          return {
            ...section,
            columns: section.columns.map((column, cIndex) => {
              if (cIndex !== columnIndex) return column;
              const newFields = [...column.fields];
              [newFields[fieldIndex - 1], newFields[fieldIndex]] = [newFields[fieldIndex], newFields[fieldIndex - 1]];
              return { ...column, fields: newFields };
            }),
          };
        });
      });
    }
  };

  const handleMoveFieldDown = (sectionIndex: number, columnIndex: number, fieldIndex: number) => {
    const fields = sections[sectionIndex].columns[columnIndex].fields;
    if (fieldIndex < fields.length - 1) {
      setSections(prevSections => {
        return prevSections.map((section, sIndex) => {
          if (sIndex !== sectionIndex) return section;
          return {
            ...section,
            columns: section.columns.map((column, cIndex) => {
              if (cIndex !== columnIndex) return column;
              const newFields = [...column.fields];
              [newFields[fieldIndex + 1], newFields[fieldIndex]] = [newFields[fieldIndex], newFields[fieldIndex + 1]];
              return { ...column, fields: newFields };
            }),
          };
        });
      });
    }
  };

  const handleMoveSectionUp = (sectionIndex: number) => {
    if (sectionIndex > 0) {
      setSections(prevSections => {
        const newSections = [...prevSections];
        [newSections[sectionIndex - 1], newSections[sectionIndex]] = [newSections[sectionIndex], newSections[sectionIndex - 1]];
        return newSections;
      });
    }
  };

  const handleMoveSectionDown = (sectionIndex: number) => {
    if (sectionIndex < sections.length - 1) {
      setSections(prevSections => {
        const newSections = [...prevSections];
        [newSections[sectionIndex + 1], newSections[sectionIndex]] = [newSections[sectionIndex], newSections[sectionIndex + 1]];
        return newSections;
      });
    }
  };

  return (
    <Layout>
      <Header className="header">
        <MenuOutlined className={`menu-icon ${sidebarVisible ? 'open' : ''}`} onClick={toggleSidebar} />
        <div className="header-title"></div>
        <div className="header-right">
          <Input className="search-bar" placeholder="Search..." prefix={<SearchOutlined />} />
          <div className="user-info">
            <Avatar src="/photos/nira_profile.jpeg" />
            <span>Nira | Artificial Intelligence</span>
          </div>
        </div>
      </Header>
      <Layout>
        <Sider width={200} className={`site-layout-background ${sidebarVisible ? 'visible' : ''}`}>
          <Sidebar />
        </Sider>

        <Content style={{ padding: '24px' }}>
          <h1>Criador de Formulários</h1>

          <div className={isDevelopment ? 'formCreatorDevelopment' : 'formCreatorProduction'} style={{ textAlign: "right" }}>
              <Button
              type="dashed"
              onClick={() => handleAddSection(1)}
              style={{ marginBottom: '16px' }}
              >
              + Seção 1 Coluna
              </Button>
              <Button
              type="dashed"
              onClick={() => handleAddSection(2)}
              style={{ marginBottom: '16px', marginLeft : '20px' }}
              >
              + Seção 2 Colunas
              </Button>
          </div>

          {sections.map((section, sectionIndex) => (
            <div
              key={sectionIndex}
            >

            <div className={isDevelopment ? 'formCreatorDevelopment' : 'formCreatorProduction'}>
              <Button
                icon={<UpOutlined />}
                onClick={() => handleMoveSectionUp(sectionIndex)}
                disabled={sectionIndex === 0}
              />
              <Button
                icon={<DownOutlined />}
                onClick={() => handleMoveSectionDown(sectionIndex)}
                disabled={sectionIndex === sections.length - 1}
              />
            </div>
            
            <div
              style={{
                marginBottom: '24px',
                border: '1px solid #fafafa',
                borderRadius: '10px',
                background: '#fafafa',
                padding: '16px',
              }}
            >

              <Row gutter={16}>
                  
                {section.columns.map((column, columnIndex) => (
                  <Col key={columnIndex} span={section.columns.length === 1 ? 24 : 12}>
                    {column.fields.map((field, fieldIndex) => (
                      <Form.Item 
                          layout="vertical"
                          key={fieldIndex} label={field.label}>
                        {field.type === 'input' && (
                          <Input
                            value={field.value}
                            onChange={(e) =>
                              handleFieldChange(
                                sectionIndex,
                                columnIndex,
                                fieldIndex,
                                e.target.value
                              )
                            }
                          />
                        )}
                        {field.type === 'select' && (
                          <Select
                            value={field.value}
                            onChange={(value) =>
                              handleFieldChange(
                                sectionIndex,
                                columnIndex,
                                fieldIndex,
                                value
                              )
                            }
                          >
                            {field?.options?.map((option: string) => (
                              <Option key={option} value={option}>
                                {option}
                              </Option>
                            ))}
                          </Select>
                        )}
                        {field.type === 'textarea' && (
                          <Input.TextArea
                            value={field.value}
                            onChange={(e) =>
                              handleFieldChange(
                                sectionIndex,
                                columnIndex,
                                fieldIndex,
                                e.target.value
                              )
                            }
                          />
                        )}

                      <div className={isDevelopment ? 'formCreatorDevelopment' : 'formCreatorProduction'}>
                          <Button
                            icon={<UpOutlined />}
                            onClick={() => handleMoveFieldUp(sectionIndex, columnIndex, fieldIndex)}
                            disabled={fieldIndex === 0}
                            style={{ marginRight: '4px' }}
                          />
                          <Button
                            icon={<DownOutlined />}
                            onClick={() => handleMoveFieldDown(sectionIndex, columnIndex, fieldIndex)}
                            disabled={fieldIndex === column.fields.length - 1}
                          />

                            <Button
                              type="default"
                              danger
                              icon={<DeleteOutlined />}
                              onClick={() =>
                                handleRemoveField(sectionIndex, columnIndex, fieldIndex)
                              }
                            >
                            </Button>
                        </div>
                      </Form.Item>
                    ))}

                    <div className={isDevelopment ? 'formCreatorDevelopment' : 'formCreatorProduction'}>
                      <Button
                        type="dashed"
                        onClick={() => showModal(sectionIndex, columnIndex)}
                        block
                        icon={<PlusOutlined />}
                      >
                        Adicionar Campo
                      </Button>
                    </div>
                  </Col>
                ))}
              </Row>

              </div>
            </div>
          ))}

          <Button
            type="primary"
            onClick={handleExport}
            block
            style={{ marginTop: '24px' }}
          >
            Exportar
          </Button>

          {/* ... (modal de adicionar campo - código idêntico ao anterior) */}

          <Modal
            title="JSON do Formulário"
            visible={isExportModalVisible}
            onCancel={handleCloseExportModal}
            footer={null}
            width={800}
          >
            <TextArea
              value={JSON.stringify(sections, null, 2)}
              rows={20}
              readOnly
            />
          </Modal>

          <Modal
            title="Adicionar Campo"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form.Item label="Título do Campo">
              <Input
                value={newFieldLabel}
                onChange={(e) => setNewFieldLabel(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Tipo">
              <Select
                value={newFieldType}
                onChange={(value) => setNewFieldType(value)}
              >
                <Option value="input">Input</Option>
                <Option value="select">Select</Option>
                <Option value="textarea">Textarea</Option>
              </Select>
            </Form.Item>
            {newFieldType === 'select' && (
              <Form.Item label="Opções (separadas por vírgula)">
                <Input
                  value={selectOptions}
                  onChange={(e) => setSelectOptions(e.target.value)}
                />
              </Form.Item>
            )}
          </Modal>
        </Content>
      </Layout>
    </Layout>
  );
};

export default FormCreator;