


import { Constants } from '../services/constants';
const APPLICATION_TOKEN = Constants.APPLICATION_TOKEN;

class MenuApi {

    static apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    static async getMenuList() {

        let userToken = localStorage.getItem('nira-token');

        const response = await fetch(`${MenuApi.apiBaseUrl}/api/general-crud/data/nira_menu`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${APPLICATION_TOKEN}`,
                'user-token': `${userToken}`
            }
        });

        const data = await response.json();

        return data;
    }

}

export default MenuApi;