// src/components/Sidebar.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importe o useNavigate
import {
    Layout,
    Avatar,
    Input
} from 'antd';
import {
    MenuOutlined,
    SearchOutlined
} from '@ant-design/icons';

import Sidebar from '../../components/Sidebar';

//import '../styles/Sidebar.css';

const { Sider } = Layout;


const AdminSidebar = ({ options }: any) => {

    const navigate = useNavigate();

    const sidebarVisible = options.sidebarVisible;

    return (
        <Sider width={200} className={`site-layout-background ${sidebarVisible ? 'visible' : ''}`}>
            <Sidebar />
        </Sider>
    );
};

export default AdminSidebar;