

import React, { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoute: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const location = useLocation();
  const token = localStorage.getItem('nira-token');

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL; 

  React.useEffect(() => {
    const verifyAuthentication = async () => {
      if (token) {
        try {
          const response = await fetch(`${apiBaseUrl}/api/users/refresh-token`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {

            const data = await response.json();
            localStorage.setItem('nira-token', data.token);

            setIsAuthenticated(true);
          } else {
            localStorage.removeItem('token');
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error('Erro ao validar token:', error);
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
    };

    verifyAuthentication();
  }, [token]); // Verifica novamente se o token mudar

  if (isAuthenticated === null) {
    return <div>Autenticando...</div>; // Renderiza uma mensagem enquanto verifica
  }

  return isAuthenticated ? (
    <Outlet /> // Renderiza a rota protegida
  ) : (
    <Navigate to="/login" state={{ from: location }} replace /> // Redireciona para o login
  );
};

export default PrivateRoute;