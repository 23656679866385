// src/components/Sidebar.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importe o useNavigate
import { Layout, Menu } from 'antd';
import { UserOutlined, DashboardOutlined, ShoppingCartOutlined } from '@ant-design/icons';

import MenuApi from '../services/menu.api';

import '../styles/Sidebar.css';

const { Sider } = Layout;

const Sidebar = () => {

  const navigate = useNavigate();

  const [menuList, setMenulist] = useState([]);

  const handleUserClick = (route: string) => { // Recebe a rota como string
    navigate(route); 
  };

  useEffect(() => {

    const fetchData = async () => {

        const records = await MenuApi.getMenuList();
        setMenulist( records );

    };

    fetchData();

}, []);

  return (
    <Sider width={200} className="site-layout-background">
      <Menu mode="inline" defaultSelectedKeys={['1']} style={{ height: '100%', borderRight: 0, backgroundColor: '#6f5cc3', color: '#fff' }}>
        
        <Menu.Item 
          onClick={() => handleUserClick('/admin')}
          key="96" icon={<UserOutlined />} style={{ color: '#fff' }}>
          Usuários
        </Menu.Item>
        <Menu.Item 
          onClick={() => handleUserClick('/admin/form-creator')}
          key="97" icon={<UserOutlined />} style={{ color: '#fff' }}>
          Form Generator
        </Menu.Item>
        <Menu.Item 
          onClick={() => handleUserClick('/admin/chat')}
          key="98" icon={<UserOutlined />} style={{ color: '#fff' }}>
          Chat
        </Menu.Item>
        <Menu.Item 
          onClick={() => handleUserClick('/admin/list/nira_teste')}
          key="99" icon={<UserOutlined />} style={{ color: '#fff' }}>
          Lista de teste
        </Menu.Item>
        {menuList.map((menuItem: any, index: number) => (
          <Menu.Item
            key={index} // Use o índice como chave temporária
            icon={<UserOutlined />} // Ícone padrão - você pode personalizar
            onClick={() => handleUserClick(menuItem.url)} // Use a rota do menuItem
            style={{ color: '#fff' }}
          >
            {menuItem.titulo} {/* Exiba o label do menuItem */}
          </Menu.Item>
        ))}

      </Menu>
    </Sider>
  );
};

export default Sidebar;