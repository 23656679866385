


import { Constants } from '../services/constants';
const APPLICATION_TOKEN = Constants.APPLICATION_TOKEN;

class EntityAPI {

    static apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    static async getEntitySchema(entityname: string) {

        let userToken = localStorage.getItem('nira-token');

        const response = await fetch(`${EntityAPI.apiBaseUrl}/api/general-crud/schema/${entityname}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${APPLICATION_TOKEN}`,
                'user-token': `${userToken}`
            }
        });

        const data = await response.json();

        return data;
    }

    static async getEntityData(entityname: string) {

        let userToken = localStorage.getItem('nira-token');

        const response = await fetch(`${EntityAPI.apiBaseUrl}/api/general-crud/data/${entityname}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${APPLICATION_TOKEN}`,
                'user-token': `${userToken}`
            }
        });

        const data = await response.json();

        return data;
    }

}

export default EntityAPI;