// src/components/Sidebar.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importe o useNavigate
import {
    Layout,
    Avatar,
    Input
} from 'antd';
import {
    MenuOutlined,
    SearchOutlined
} from '@ant-design/icons';

//import '../styles/Sidebar.css';

const { Header } = Layout;


const AdminHeader = ( {options} : any ) => {

    const navigate = useNavigate();

    const sidebarVisible = options.sidebarVisible;
    const toggleSidebar = options.toggleSidebar;

    return (
        <Header className="header">
            <MenuOutlined className={`menu-icon ${sidebarVisible ? 'open' : ''}`} onClick={toggleSidebar} />
            <div className="header-title"></div>
            <div className="header-right">
                <Input className="search-bar" placeholder="Search..." prefix={<SearchOutlined />} />
                <div className="user-info">
                    <Avatar src="../../photos/nira_profile.jpeg" />
                    <span>Nira | Artificial Intelligence</span>
                </div>
            </div>
        </Header>
    );
};

export default AdminHeader;