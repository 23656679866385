

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Users from './pages/Users';
import Meetings from './pages/Meetings';
import Admin from './pages/Admin';
import Edit from './pages/Edit';
import FormCreator from './pages/FormCreator';
import PrivateRoute from './routes/PrivateRoutes';
import ChatAdmin from './pages/ChatAdmin';
import ListPage from './pages/ListPage';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/users" element={<Users/>} />
        <Route path="/meetings" element={<Meetings />} />
        
        <Route element={<PrivateRoute />}> 
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/admin/edit" element={<Edit />} />
          <Route path="/admin/form-creator" element={<FormCreator />} />
          <Route path="/admin/chat" element={<ChatAdmin />} />
          <Route path="/admin/list/:entityname" element={<ListPage />}  /> 
        </Route>
        
        
      </Routes>
    </Router>
  );
};

export default App;
