import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Layout, 
  Table, 
  Tag, 
  Space, 
  Button, 
  Avatar, 
  Input, 
  Tabs,
  Modal,
  Checkbox,
  Row,
  Col,
  Select 
} from 'antd';
import { 
  PlusOutlined, 
  ImportOutlined, 
  ExportOutlined, 
  FilterOutlined, 
  EditOutlined, 
  DeleteOutlined, 
  LoginOutlined, 
  MenuOutlined, 
  SearchOutlined, 
  UserOutlined 
} from '@ant-design/icons';
import Sidebar from '../components/Sidebar';
import '../styles/Admin.css';

const { Header, Content, Sider } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;

interface FilterValues {
  [key: string]: string;
}

interface User {
  photo: string;
  name: string;
  mobile: string;
  email: string;
  status: string;
  [key: string]: string | undefined; // Index Signature
}

const Admin: React.FC = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filteredColumns, setFilteredColumns] = useState<string[]>([
    'photo',
    'name',
    'mobile',
    'email',
    'status',
    'operation'
  ]);
  const [filterValues, setFilterValues] = useState<FilterValues>({});
  
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const navigateToEdit = function(){
    navigate('/admin/edit');
  }

  const users : User[] = [
    { photo: 'photos/nira_profile.jpeg', name: 'George Lindoff', mobile: '+4 315 23 62', email: 'carlsen@armand.no', status: 'Active' },
    { photo: 'photos/nira_profile.jpeg', name: 'Eric Dyer', mobile: '+2 134 25 65', email: 'cristofer.ajer@lone.no', status: 'Active' },
    { photo: 'photos/nira_profile.jpeg', name: 'Haitam Alassami', mobile: '+1 345 22 21', email: 'haitam@gmail.com', status: 'Active' },
    { photo: 'photos/nira_profile.jpeg', name: 'Michael Campbel', mobile: '+1 756 52 73', email: 'camp@hotmail.com', status: 'Inactive' },
    { photo: 'photos/nira_profile.jpeg', name: 'Ashley Williams', mobile: '+1 965 43 11', email: 'williams.ash@nevil.com', status: 'Active' },
    { photo: 'photos/nira_profile.jpeg', name: 'Vanessa Paradi', mobile: '+4 644 12 38', email: 'paradi.van@google.com', status: 'Active' },
    { photo: 'photos/nira_profile.jpeg', name: 'Lora Palmer', mobile: '+1 909 34 33', email: 'lora.palm@gmail.com', status: 'Active' },
    { photo: 'photos/nira_profile.jpeg', name: 'Christy Newborn', mobile: '+6 254 75 12', email: 'chris@amazon.com', status: 'Inactive' },
    { photo: 'photos/nira_profile.jpeg', name: 'Nick Jackel', mobile: '+4 668 43 12', email: 'jackel@google.com', status: 'Active' },
    { photo: 'photos/nira_profile.jpeg', name: 'Tora Laundren', mobile: '+1 343 63 32', email: 'tora.lan@gmail.com', status: 'Active' },
    { photo: 'photos/nira_profile.jpeg', name: 'Malisha Manis', mobile: '+5 234 23 00', email: 'manisha@new.com', status: 'Active' },
  ];

  const handleFilterChange = (columnName: string, checked: boolean) => {

    if (checked) {
      setFilteredColumns([...filteredColumns, columnName]);
    } else {
      setFilteredColumns(filteredColumns.filter(column => column !== columnName));
    }
  };

  const handleFilterValueChange = (columnName: string, value: string) => {
    setFilterValues({
      ...filterValues,
      [columnName]: value, 
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const filteredUsers = users.filter(user => {
    for (const columnName of Object.keys(filterValues)) {
      const filterValue = filterValues[columnName].toLowerCase(); 
      
      if (columnName in user) { 

        if( ! user[columnName] ) return false;

        if (user && user[columnName] && user[columnName].toLowerCase().indexOf(filterValue) === -1) { 
          return false;
        }
      }
    }
    return true;
  });

  const columns = [
    {
      title: 'Photo',
      dataIndex: 'photo',
      key: 'photo',
      render: (text: string) => <Avatar src={text} />,
    },
    {
      title: 'Member name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag color={status === 'Active' ? 'green' : 'red'}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Operation',
      key: 'operation',
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} />
          <Button icon={<DeleteOutlined />} />
          <Button icon={<LoginOutlined />} />
        </Space>
      ),
    },
  ]
  
  
  const visibleColumns = columns.filter(column => filteredColumns.includes(column.key)); 

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="header">
        <MenuOutlined className={`menu-icon ${sidebarVisible ? 'open' : ''}`} onClick={toggleSidebar} />
        <div className="header-title"></div>
        <div className="header-right">
          <Input className="search-bar" placeholder="Search..." prefix={<SearchOutlined />} />
          <div className="user-info">
            <Avatar src="photos/nira_profile.jpeg" />
            <span>Nira | Artificial Intelligence</span>
          </div>
        </div>
      </Header>
      <Layout>
        <Sider width={200} className={`site-layout-background ${sidebarVisible ? 'visible' : ''}`}>
          <Sidebar />
        </Sider>
        <Content style={{ margin: '24px 16px 0', padding: 0 }}>
          <div className="listTitle">
            <h2>Usuários</h2>
          </div>
          <div className="controls">
            
            <Button type="default" icon={<FilterOutlined />} onClick={showModal}>
              Filter
            </Button>

            <Button onClick={navigateToEdit} type="primary" icon={<PlusOutlined />}>
              Add new
            </Button>
            
          </div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="All" key="1">
              <Table dataSource={filteredUsers} columns={visibleColumns} rowKey="name" />
            </TabPane>
            <TabPane tab="Unverified" key="2">
              <Table dataSource={users.filter(user => user.status === 'Unverified')} columns={visibleColumns} rowKey="name" />
            </TabPane>
            <TabPane tab="Blocked" key="3">
              <Table dataSource={users.filter(user => user.status === 'Blocked')} columns={visibleColumns} rowKey="name" />
            </TabPane>
            <TabPane tab="Active" key="4">
              <Table dataSource={users.filter(user => user.status === 'Active')} columns={visibleColumns} rowKey="name" />
            </TabPane>
          </Tabs>

          <Modal title="Filter" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <Row>
              {columns.map(column => (
                <Row>
                  <Col span={24} key={column.key}>
                    <Checkbox checked={filteredColumns.includes(column.key)} onChange={(e) => handleFilterChange(column.key, e.target.checked)}>
                      {column.title}
                    </Checkbox>
                    
                      <Input 
                        placeholder={`Filter ${column.title}`} 
                        onChange={(e) => handleFilterValueChange(column.key, e.target.value)} 
                      />
                  </Col>
                </Row>
              ))}
            </Row>
          </Modal>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Admin;