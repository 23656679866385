// src/pages/Login.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import '../styles/Login.css'; // Importando o CSS personalizado

const Login: React.FC = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    try {

      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL; 

      if (!apiBaseUrl) {
        throw new Error('Variável de ambiente REACT_APP_API_BASE_URL não definida.');
      }

      const { email, password } = values;
      const response = await fetch(`${apiBaseUrl}/api/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Usuário ou senha inválidos'); 
      }

      const data = await response.json();
      localStorage.setItem('nira-token', data.token);
      navigate('/admin'); 

    } catch (err: any) { 
      setError('Usuário ou senha inválidos');
      message.error(err.message); 
    }
  };

  return (
    <div className="login-container">
      <Form
        name="login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <h2 className="login-title">Simple Assistant</h2>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Por favor, insira seu email!' }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Por favor, insira sua senha!' }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Senha" />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Lembrar de mim</Checkbox>
          </Form.Item>
          <a className="login-form-forgot" href="">
            Esqueci minha senha
          </a>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Entrar
          </Button>
        </Form.Item>
        {error && <p className="login-error">{error}</p>}
        <Form.Item>
          <p>
            Não possui uma conta? <a href="">Cadastre-se</a>
          </p>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;