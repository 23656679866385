import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation  } from 'react-router-dom';
import {
    Layout,
    Table,
    Tag,
    Space,
    Button,
    Avatar,
    Input,
    Tabs,
    Modal,
    Checkbox,
    Row,
    Col,
    Select
} from 'antd';
import {
    PlusOutlined,
    ImportOutlined,
    ExportOutlined,
    FilterOutlined,
    EditOutlined,
    DeleteOutlined,
    LoginOutlined,
    MenuOutlined,
    SearchOutlined,
    UserOutlined
} from '@ant-design/icons';

import EntityApi from '../services/entity.api';

import AdminHeader from '../components/admin/Header';
import AdminSidebar from '../components/admin/AdminSidebar';

import Sidebar from '../components/Sidebar';
import '../styles/Admin.css';

const { Header, Content, Sider } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;

interface FilterValues {
    [key: string]: string;
}

interface User {
    photo: string;
    name: string;
    mobile: string;
    email: string;
    status: string;
    [key: string]: string | undefined; // Index Signature
}

const Admin: React.FC = () => {

    const location = useLocation();

    const { entityname } = useParams(); // Acesse o parâmetro da rota

    const [entityTitle, setEntityTitle] = useState("");

    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [filteredColumns, setFilteredColumns] = useState<string[]>([]);
    const [filterValues, setFilterValues] = useState<FilterValues>({});

    const [records, setRecords] = useState<any[]>([]); // Use useState para records
  const [columns, setColumns] = useState<any[]>([]); // Use useState 

    const navigate = useNavigate();

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    const navigateToEdit = function () {
        navigate('/admin/edit');
    }

    const handleFilterChange = (columnName: string, checked: boolean) => {

        if (checked) {
            setFilteredColumns([...filteredColumns, columnName]);
        } else {
            setFilteredColumns(filteredColumns.filter(column => column !== columnName));
        }
    };

    const handleFilterValueChange = (columnName: string, value: string) => {
        setFilterValues({
            ...filterValues,
            [columnName]: value,
        });
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const operationColumn = {
        title: 'Operation',
        key: 'operation',
        render: (_: any, record: any) => (
            <Space size="middle">
                <Button icon={<EditOutlined />} />
                <Button icon={<DeleteOutlined />} />
                <Button icon={<LoginOutlined />} />
            </Space>
        ),
    };

    const visibleColumns = columns.filter( ( column : any) => filteredColumns.includes(column.key));

    const recoveryAttributesBySchema = function( entitySchema : any ){

        let listFields : any = [];

        if( ! entitySchema || ! entitySchema.attributes || ! entitySchema.attributes.fields ) return listFields;

        let primaryKey = {
            title: "Código",
            dataIndex: "id",
            key: "id"
        }

        listFields.push( primaryKey );

        for( let i = 0; i < entitySchema.attributes.fields.length; i++){

            let field = entitySchema.attributes.fields[i];

            let name = field.label;
            name = name.toLowerCase();
            name = name.replace("ç", "c");
            name = name.replace("ô", "o");
            name = name.replace("õ", "o");
            name = name.replace("ã", "a");
            name = name.replace("á", "a");

            let to = {
                title: field.label,
                dataIndex: name,
                key: name
            }

            listFields.push( to );
        }

        listFields.push( operationColumn );

        return listFields;
    }

    useEffect(() => {

        const fetchData = async () => {

            const entitySchema = await EntityApi.getEntitySchema( entityname );
            const entityData = await EntityApi.getEntityData( entityname );
            const entityColumns = recoveryAttributesBySchema( entitySchema );
            
            let visible = [];

            for( let i = 0; i < entityColumns.length; i++ ){
                visible.push( entityColumns[i]["key"] );
            }

            setEntityTitle( entitySchema.title );
            setFilteredColumns( visible )
            setColumns( entityColumns );
            setRecords( entityData );

        };

        fetchData();

    }, [location]);

    return (
        <Layout style={{ minHeight: '100vh' }}>

            <AdminHeader options={{ "sidebarVisible": sidebarVisible, "toggleSidebar": toggleSidebar }} />

            <Layout>

                <AdminSidebar options={{ "sidebarVisible": sidebarVisible }} />

                <Content style={{ margin: '24px 16px 0', padding: 0 }}>
                    <div className="listTitle">
                        <h2>{entityTitle}</h2>
                    </div>
                    <div className="controls">

                        <Button type="default" icon={<FilterOutlined />} onClick={showModal}>
                            Filter
                        </Button>

                        <Button onClick={navigateToEdit} type="primary" icon={<PlusOutlined />}>
                            Add new
                        </Button>

                    </div>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Todos os registros" key="0">
                            <Table dataSource={records} columns={visibleColumns} rowKey="id" />
                        </TabPane>
                    </Tabs>

                    <Modal title="Filter" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        
                            {columns.map( ( column : any ) => (
                                <Row>
                                    <Col span={24} key={column.key}>
                                        <Checkbox checked={filteredColumns.includes(column.key)} onChange={(e) => handleFilterChange(column.key, e.target.checked)}>
                                            {column.title}
                                        </Checkbox>
                                    </Col>
                                </Row>
                            ))}
                    </Modal>
                </Content>
            </Layout>
        </Layout>
    );
};

export default Admin;