


import { Constants } from '../services/constants';
const APPLICATION_TOKEN = Constants.APPLICATION_TOKEN;

class ChatAPI {

    static apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    static async getChatsByUser() {

        let userToken = localStorage.getItem('nira-token');
        let userId = Constants.USER_ID;

        const response = await fetch(`${ChatAPI.apiBaseUrl}/api/chat/user/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${APPLICATION_TOKEN}`,
                'user-token': `${userToken}`
            }
        });

        const data = await response.json();

        return data;
    }

    static async getChatHistory( chatId : any) {

        let userToken = localStorage.getItem('nira-token');
        let userId = Constants.USER_ID;

        const response = await fetch(`${ChatAPI.apiBaseUrl}/api/chat/user/${userId}/chats/${chatId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${APPLICATION_TOKEN}`,
                'user-token': `${userToken}`
            }
        });

        const data = await response.json();

        return data;
    }

    static async createChat( name : string,
        description : string,
        namespace : string,
        temperature : string,
        llm : string,
        systemPrompt : string
    ) {

        let userToken = localStorage.getItem('nira-token');
        let userId = Constants.USER_ID;

        const payload = JSON.stringify({
            userId,
            name ,
            description,
            namespace,
            temperature,
            llm,
            systemPrompt
        });

        const response = await fetch(`${ChatAPI.apiBaseUrl}/api/chat`, {
            method: 'POST',
            body: payload,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${APPLICATION_TOKEN}`,
                'user-token': `${userToken}`
            }
        });

        const data = await response.json();

        return data;
    }

    static async removeChat( chatId : any) {

        let userToken = localStorage.getItem('nira-token');
        let userId = Constants.USER_ID;

        const response = await fetch(`${ChatAPI.apiBaseUrl}/api/chat/chat/${chatId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${APPLICATION_TOKEN}`,
                'user-token': `${userToken}`
            }
        });

        const data = await response.json();

        return data;
    }

}

export default ChatAPI;