import React, { useEffect, useState } from 'react';
import api from '../services/api';

const Meetings: React.FC = () => {
  const [meetings, setMeetings] = useState([]);

  useEffect(() => {
    const fetchMeetings = async () => {
      const response = await api.get('/meetings');
      setMeetings(response.data);
    };
    fetchMeetings();
  }, []);

  return (
    <div>
      <h2>Meetings</h2>
      <ul>
        {meetings.map((meeting: any) => (
          <li key={meeting.id}>{meeting.subject}</li>
        ))}
      </ul>
    </div>
  );
};

export default Meetings;